import React, {useEffect} from 'react';
import {Link} from "react-router-dom";

function Store({store}) {
    useEffect(() => {
        const google = window.google;
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({'address': store.address}, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                const latitude = results[0].geometry.location.lat();
                const longitude = results[0].geometry.location.lng();
                const collection = {lat: latitude, lng: longitude}
                const map = new google.maps.Map(document.getElementById(`store${store.id}`), {
                    center: collection,
                    zoom: 10
                });
                new google.maps.Marker({
                    position: collection,
                    map: map,
                    label: {
                        color: 'black',
                        text: store.name,
                    },
                });
            }
        });
    }, [store.address, store.id, store.name])

    return (
        <div className="row">
            <div className="col-md-6  d-none d-sm-block">
                <div
                    id={`store${store.id}`}
                    className='map'
                />
            </div>
            <div className="col-md-6 p-4"
            >
                <p className="card-text font-weight-bold mt-2">
                    <strong>{store.name}</strong>
                </p>
                <p>
                    <Link to={`1/menu`} className={'btn b-primary'}>Menu</Link>
                </p>
                <p className="card-text">
                    {store.businessHour}
                </p>
                <p className="card-text">
                    <a href={`tel:${store.phone}`} className="text-dark">Phone: {store.phone}</a>
                </p>
                <p className="card-text">Address: {store.address}</p>
                <button type="button"
                        className="btn b-primary mb-2"
                        disabled={store.buttonName === 'Coming Soon'}
                        onClick={() => {
                            window.location.href = store.link
                        }}
                >
                    Pickup
                </button>
                <button type="button"
                        className="btn b-primary mb-2 ml-2"
                        disabled={store.delivery === ''}
                        onClick={() => {
                            window.location.href = store.delivery
                        }}
                >
                    Delivery
                </button>
                <button type="button"
                        className={`btn b-primary text-white mb-2 ml-2 ${store.reservation === '' ? 'd-none' : ''}`}
                        disabled={store.reservation === ''}
                        onClick={() => {
                            window.location.href = store.reservation
                        }}
                >
                    Reservation
                </button>
                <button type="button"
                        className={`btn b-primary text-white mb-2 ml-2 ${store.virtual_tour_link === '' ? 'd-none' : ''}`}
                        disabled={store.virtual_tour_link === ''}
                        onClick={() => {
                            window.location.href = store.virtual_tour_link
                        }}
                >
                    Virtual Tour
                </button>
            </div>
        </div>
    );
}

export default Store;
