import React from 'react';

const Footer = (props) => {
    return (
        <footer className="text-muted">
            <div className="container py-5">
                <div className="row">
                    {/*<div className="col-xs-12 col-lg-6">*/}
                    {/*    <ul className="list-unstyled">*/}
                    {/*        <li><a href={`tel:${Constants.Phone}`}*/}
                    {/*               className="text-dark">Phone: 757-689-8888</a></li>*/}
                    {/*        <li><span*/}
                    {/*            className="text-dark">Address: {Constants.Address}</span>*/}
                    {/*        </li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                    <div className="col-xs-12 col-lg-6">
                        <p className="text-dark">BOIL BAY. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
