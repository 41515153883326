const Stores = [
    {
        id: 1,
        name: 'Boil Bay (Virginia Beach)',
        shortName: 'Virginia Beach',
        phone: '757-689-8888',
        businessHour: "Sun to Thur 12:30 pm to 10:00 pm, Fri to Sat 12:30 pm to 10:30 pm",
        address: "3244 Holland Road, Suite 100A, Virginia Beach, VA 23453, USA",
        link: "https://www.mealage.com/r.jsp?id=31016",
        reservation: "https://www.yelp.com/reservations/boil-bay-virginia-beach",
        delivery: "https://order.online/store/BoilBayCajunSeafood-941126/en-US/?hideModal=true&pickup=true",
        deliveryAndPickup: "https://order.online/store/BoilBayCajunSeafood-941126/en-US/?hideModal=true&pickup=true",
        menu: [
            'https://spa-console.s3.amazonaws.com/Boilbay/1/1.jpg',
            'https://spa-console.s3.amazonaws.com/Boilbay/1/2.jpg',
            'https://spa-console.s3.amazonaws.com/Boilbay/1/3.jpg',
        ],
        virtual_tour_link: "",
        buttonName: 'PICK UP'
    },
    {
        id: 3,
        name: 'Boil Bay (Norfolk)',
        shortName: 'Norfolk',
        phone: '757-962-1088',
        businessHour: "Sun to Thur 12:00 pm to 10:00 pm, Fri to Sat 12:00 pm to 10:30 pm",
        address: "5957 East Virginia Beach Blvd, Norfolk, VA 23502, USA",
        link: "https://www.mealage.com/r.jsp?id=31028",
        reservation: "https://www.yelp.com/reservations/boil-bay-seafood-city-norfolk",
        delivery: "https://order.online/store/BoilBay-1299630/en-US/?hideModal=true&pickup=true",
        deliveryAndPickup: "https://order.online/store/BoilBay-1299630/en-US/?hideModal=true&pickup=true",
        virtual_tour_link: "",
        buttonName: 'PICK UP'
    },
    {
        id: 2,
        name: 'Boil Bay (Colonial Heights)',
        shortName: 'Colonial Heights',
        phone: '804-895-6266',
        businessHour: "Sun to Thur 12:00 pm to 9:30 pm, Fri to Sat 12:00 pm to 10:30 pm",
        address: "1829 Southpark Blvd, Colonial Heights, VA 23834, USA",
        link: "https://www.mealage.com/r.jsp?id=31011",
        reservation: "https://www.yelp.com/reservations/boil-bay-colonial-heights-3",
        delivery: "https://order.online/store/BoilBay-1061092/en-US/?hideModal=true&pickup=true",
        deliveryAndPickup: "https://order.online/store/BoilBay-1061092/en-US/?hideModal=true&pickup=true",
        virtual_tour_link: "",
        buttonName: 'PICK UP'
    },
    {
        id: 5,
        name: 'Boil Bay (Chesterfield)',
        shortName: 'Chesterfield',
        phone: '804-464-1486',
        businessHour: "Sun to Thur 12 pm to 10:00 pm, Fri to Sat 12 pm to 11:00 pm",
        address: "1321 Carmia Way, North Chesterfield, VA 23235, USA",
        link: "https://www.mealage.com/r.jsp?id=31047",
        reservation: "https://www.yelp.com/reservations/boil-bay-north-chesterfield-bon-air",
        delivery: "https://order.online/store/BoilBayCajunSeafoodandBar-2428366/en-US/?hideModal=true&pickup=true",
        deliveryAndPickup: "https://order.online/store/BoilBayCajunSeafoodandBar-2428366/en-US/?hideModal=true&pickup=true",
        virtual_tour_link: "https://tour.richmondvirtualtour.com/tours/Zue2RlnoF",
        buttonName: 'PICK UP'
    },
    {
        id: 4,
        name: 'Boil Bay (Henrico)',
        shortName: 'Henrico',
        phone: '804-658-0953',
        businessHour: "Sun to Thur 12 pm to 10 pm, Fri to Sat 12 pm to 11 pm",
        address: "8161 Brook Rd, Richmond, VA 23227, USA",
        link: "https://www.mealage.com/r.jsp?id=31045",
        reservation: "https://www.yelp.com/reservations/boil-bay-cajun-seafood-and-bar-richmond",
        delivery: "https://order.online/store/BoilBayCajunSeafoodHenricoVA-1856163/en-US/?hideModal=true&pickup=true",
        virtual_tour_link: "https://tour.richmondvirtualtour.com/tours/CrVm2sr3e",
        deliveryAndPickup: "https://order.online/store/BoilBayCajunSeafoodHenricoVA-1856163/en-US/?hideModal=true&pickup=true",
        buttonName: 'PICK UP'
    }
];

export default Stores;
