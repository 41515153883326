import React, {useEffect} from 'react';
import './App.css';
import Header from "./components/header";
import Constants from './constants'
import Footer from "./components/footer";
import Locations from "./components/locations";
import stores from "./json/stores";
import Background from './food.jpg';

function App() {

    useEffect(() => {
        document.title = Constants.App;
        const google = window.google;
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({'address': Constants.Address}, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                const latitude = results[0].geometry.location.lat();
                const longitude = results[0].geometry.location.lng();
                const collection = {lat: latitude, lng: longitude}
                const map = new google.maps.Map(document.getElementById('map'), {
                    center: collection, zoom: 10
                });
                new google.maps.Marker({
                    position: collection, map: map, label: {
                        color: 'black', text: Constants.App,
                    },
                });
                new google.maps.Circle({
                    strokeColor: '#f45766',
                    strokeOpacity: 0.8,
                    strokeWeight: 1,
                    fillColor: '#f45766',
                    fillOpacity: 0.35,
                    map: map,
                    center: collection,
                    radius: 12874
                });
            }
        });
    }, []);

    return (<>
        <Header/>
        <main role="main">
            <section className="jumbotron bg-header text-center bg-white mb-0 pb-3 overlay overlay-color-dark"
                     style={{
                         backgroundImage: `url(${Background})`, minHeight: 500
                     }}
            >
                <div className="container position-relative d-flex justiy-content-center align-items-center flex-column"
                     style={{maxWidth: "60rem", zIndex: 10}}>
                    <h1 className="text-white mt-5">Boil Bay Seafood & Bar</h1>
                    <p className="lead text-white mb-5">Give us a try, you will be delighted. We believe that good times
                        deserve great food.</p>
                    <div className="form-row d-flex justify-content-center align-items-center"
                         style={{maxWidth: "30rem", zIndex: 10}}>
                        <div className="form-group col-sm-12 col-md">
                            <button type="button"
                                    className="btn btn-block b-primary mb-2 text-capitalize"
                                    onClick={() => window.location.href = 'https://order.online/business/boil-bay-421258/en-US'}
                            >Find closest boil bay near you
                            </button>
                        </div>
                        {stores.map(store => {
                            return (<>
                                <div key={`store_banner_button_${store.id}`}
                                     className="form-group col-sm-12 col-sm">
                                    <button type="button"
                                            className="btn btn-block b-primary mb-2 text-capitalize"
                                            onClick={() => window.location.href = store.deliveryAndPickup}
                                    >{store?.shortName} delivery & pickup
                                    </button>
                                </div>
                                <div key={`store_banner_button_${store.id}`}
                                     className="form-group col-sm-12 col-sm">
                                    <button type="button"
                                            className="btn btn-block b-primary mb-2 text-capitalize"
                                            onClick={() => window.location.href = store.link}
                                    >{store?.shortName} to go order
                                    </button>
                                </div>
                            </>)
                        })}
                    </div>
                </div>
            </section>
            <Locations/>
            <section className="py-5 bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 my-2">
                            <img src="https://spa-console.s3.amazonaws.com/Boilbay/food1.jpeg" className="img-fluid"
                                 alt=""/>
                        </div>
                        <div className="col-md-4 my-2">
                            <img src="https://spa-console.s3.amazonaws.com/Boilbay/food2.jpeg" className="img-fluid"
                                 alt=""/>
                        </div>
                        <div className="col-md-4 my-2">
                            <img src="https://spa-console.s3.amazonaws.com/Boilbay/food3.jpeg" className="img-fluid"
                                 alt=""/>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <Footer/>
    </>);
}

export default App;
