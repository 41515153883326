import React from 'react';
import Title from "./section.title";
import stores from "../json/stores";
import Store from "./store";

const Locations = (props) => {

    const storeList = stores.map((store, index) => {
        return (
            <React.Fragment key={index}>
                <Store
                    store={store}
                    key={index}
                />
                {index === stores.length - 1 ? null : <hr/>}
            </React.Fragment>
        )
    });

    return (
        <div id="locations" className="pb-5">
            <section className="bg-white">
                <div className="container">
                    <Title
                        title={'Locations'}
                    />
                    {storeList}
                </div>
            </section>
        </div>
    );
};

export default Locations;
