import React from 'react';

const Title = ({title}) => {
    return (
        <>
            <div className="section-title text-center">
                <em style={{
                    fontFamily: "orpheus-pro",
                    fontWeight: 400,
                    letterSpacing: 0,
                    textTransform: "ome",
                    lineHeight: "1.4em",
                    fontSize: '2rem'
                }}>— {title} —</em>
                <hr className="custom-divider mb-5"/>
            </div>
        </>
    );
};

export default Title;
