import React, {useEffect} from 'react';
import './App.css';
import Header from "./components/header";
import Constants from './constants'
import Footer from "./components/footer";

function Menu() {
    useEffect(() => {
        document.title = Constants.App;
    }, []);

    return (
        <>
            <Header/>
            <main role="main">
                <div className="container">
                    <div className="container position-relative">
                        <h1 className="mt-5">Boil Bay</h1>
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-6">
                            <img src={'/1.jpg'} className="img-fluid" alt="menu"/>
                        </div>
                        <div className="col-md-6">
                            <img src={'/2.jpg'} className="img-fluid" alt="menu"/>
                        </div>
                        <div className="col-md-6">
                            <img src={'/3.jpg'} className="img-fluid" alt="menu"/>
                        </div>
                    </div>
                </div>
            </main>
            <Footer/>
        </>
    );
}

export default Menu;
