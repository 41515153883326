const Constants = {
    App: 'Boil Bay',
    Phone: 7576898888,
    location1: "https://www.mealage.com/r.jsp?id=31016",
    location2: "https://www.mealage.com/r.jsp?id=31011",
    location3: "https://www.mealage.com/r.jsp?id=31028",
    location4: "https://www.mealage.com/r.jsp?id=31045",
    location5: "https://www.mealage.com/r.jsp?id=31047",
    Address: "3244 Holland Road Suite 100 Virginia Beach VA 23453",
};

export default Constants;
