import React from 'react';
import {Link} from "react-router-dom";

const Header = (props) => {
    return (
        <header>
            <div className="collapse bg-dark" id="navbarHeader">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-8 col-md-7 py-4">
                            <h4 className="text-white">About Us</h4>
                            <p className="text-muted">Boil Bay in Virginia Beach VA. Everything is prepared with high
                                quality, rich taste and fresh food waiting for you to be served. Food in our restaurant
                                are specially made for your taste and health that will capture and reach your
                                expectations. Our experienced Chefs ensure to give you the best food service and make
                                your every order with us a memorable one. We are now online to serve you more!</p>
                        </div>
                        <div className="col-sm-4 offset-md-1 py-4">
                            <h4 className="text-white">Contact</h4>
                            <ul className="list-unstyled">
                                <li><a href={`tel:7576898888`}
                                       className="text-white">Phone: 757-689-8888</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="navbar navbar-light bg-white navbar-shadow">
                <div className="container d-flex justify-content-between">
                    <div>
                        <Link to="/" className="navbar-brand d-flex align-items-center">
                            <img src='/logo.png' alt="boilbay-logo" height="60"/>
                        </Link>
                    </div>
                    <div className="d-flex justify-content-between">
                        <Link to="/1/menu" className="navbar-brand d-flex align-items-center text-dark">
                            Menu
                        </Link>
                        <Link to="/" className="navbar-brand d-flex align-items-center text-dark">
                            Home
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarHeader" aria-controls="navbarHeader" aria-expanded="false"
                                aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
